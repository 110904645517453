function AIProxy() {
    return (

      <div className="details-body">

         <p>AIProxy is a tool to help app developers secure API keys and endpoints without needing to build and maintain a backend.</p>

         <p>It started when my cofounder and I were building several AI apps, and we needed an easy way to secure our API keys before going to production. After searching for solutions, we realized there was no simple way to do this. Even worse, there was a lot of misguided advice out there on how to secure API keys properly.</p>

         <p>We got to work talking to developers and learning about their pain points. Within a few weeks, we built an MVP and landed our first few customers. Fast forward to today, and we've grown to thousands of developers passing millions of requests through our service.</p>

      </div>

    );
  }

  export default AIProxy;