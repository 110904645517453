function Joypad() {
    return (

      <div className="details-body">

        <p>Joypad started as a fun weekend hack to turn your smartphone into a game controller. After releasing the app on iOS customers started requesting more controllers and features. My co-founder and I eventually started Joypad Inc and released the Joypad SDK. Through sales, developer outreach, and PR initiates we were able to integrate the Joypad SDK in over 100 games on desktop and iOS.</p>

      </div>

    );
}

export default Joypad;