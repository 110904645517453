function Letterfall() {
    return (

      <div className="details-body">

        <p>I love word games and wanted to build my own, so I started prototyping some basic game mechanics in Swift that combined elements of two of my favorite games, Scrabble and Tetris.</p>

        <p>Pretty quickly into the process, I felt like I had a fun game on my hands, so I started sending test builds out to friends and family to get feedback. Over the course of a few months, I dialed in the gameplay, polished the UI, and added additional features like leaderboards and achievements.</p>

        <p>I launched it on the App Store and posted it on Product Hunt on May 21st, 2022. The reception was great, and it reached the 4th top product spot on PH. Eventually I'll add more features and update the app, but for now I'm glad I was able to bring this idea to life.</p>

      </div>

    );
}

export default Letterfall;