function Marketplace() {
  return (

      <div className="details-body">

        <p>From 2017 to 2022, I led design initiatives for Facebook Marketplace, driving work across core buyer and seller experiences. My work focused on improving the main feed, navigation, product detail pages, messaging, internalization, and other end-to-end flows.</p>

        <p>Over one billion people use the Marketplace Feed each month to find and buy items on the platform, which was one of my main responsibilities.</p>

        <p>One of my most impactful contributions was establishing a dedicated design infrastructure team on Marketplace, which I led for two years. My team was responsible for designing and maintaining the core design components of Marketplace and centralizing resources and processes across teams.</p>

      </div>

  );
}

export default Marketplace;