function AppleWatch() {
    return (

      <div className="details-body">

         <p>Before the Apple Watch was announced there was a lot of speculation around what the device would look like. I was excited about the wearables market so I decided to create my own concept as a fun design exploration. I started with some initial sketches then created a 3D rendering showing how the watch could potentially work. Over the next few days the concept went viral on several major news sites and eventually appeared on the Today Show.</p>

      </div>

    );
}

export default AppleWatch;