function FB4D() {
    return (

      <div className="details-body">

        <p>In 2015 my team started working on an extensive redesign of the Facebook Developer site. The goal was to design and implement a new design system that could scale with our continued growth across marketing, documentation and developer tools.</p>

        <p>One of the biggest improvments we made in the process was transitioning the entire site to our internal CMS to make it easier for the rest of the company to contribute content. Moving to our CMS also allowed us to translate the site into seventeen different languages.</p>

        <p>Throughtout the process I led design and worked closely with engineering and content writers to ensure we're creating a scalable and intuitive architecture for the site. To help the team ship in time for F8, I also built the front-end for a significant portion of the marketing site, navigation, documention and developer tools.</p>

      </div>

    );
}

export default FB4D;