import archiveData from './archiveData.json';
import './Archive.css';

function Archive() {
  return (

    <div className="archive">

        {archiveData.map((item, index) => (
            <div className="archive-item" key={index}>
                {item.image.endsWith('.mp4') ? (
                    <video loop muted playsInline controls>
                        <source src={`${item.image}#t=0.001`} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                ) : (
                    <img src={item.image} alt={item.alt} />
                )}
                <div className="archive-info">
                    <div className="info-col">
                        <div className="info-detail">{item.project}</div>
                    </div>
                </div>
            </div>
        ))}

    </div>

  );
}

export default Archive;