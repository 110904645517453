function MetaPrototyping() {
    return (

        <div className="details-body">

          <p>During my time at Meta, I created hundreds of prototypes for new products and features. I strongly believe that prototyping can be a designer's most powerful skill, and I attribute a lot of my success to it.</p>

          <p>I invested a lot of time teaching other designers how to be more proficient with all the available tools. I created workshops, lessons, and recorded video tutorials to help them learn how to prototype and apply it to their work.</p>

          <p>One of the most successful projects I created was a video tutorial series called Origami Shorts. This series showed designers how to use Origami Studio, which is Meta's internal prototyping tool. Every week I would publish a new video internally that would walk viewers through how to build different features from the family of apps (Facebook, Messenger, Instagram, WhatsApp).</p>

        </div>

    );
  }

  export default MetaPrototyping;