import './About.css';
import { motion } from 'motion/react'

function About() {
  return (

    <div className="about">

        <div className="about-photos">
          <motion.img
            src="./images/about/surf.webp"
            initial={{ x: 150, y: 0, scale: 0.75, rotate: 0, opacity: 0 }} // Initial position and scale
            animate={{ x: 0, y: 10, scale: 1, rotate: -10, opacity: 1 }} // Move left and scale up
            transition={{ type: "spring", stiffness: 100, delay: 0.2 }}
          />
          <motion.img
            src="./images/about/maine.webp"
            initial={{ x: 150, y: 0, scale: 0.75, rotate: 0, opacity: 0 }} // Initial position and scale
            animate={{ x: 290, y: 10, scale: 1, rotate: 8, opacity: 1 }} // Move left and scale up
            transition={{ type: "spring", stiffness: 100, delay: 0.3 }}
          />
          <motion.img
            src="./images/about/office.webp"
            initial={{x: 150, y: 0, scale: 0.75, rotate: 0, opacity: 0 }} // Initial position and scale
            animate={{x: 150, y: -15, scale: 1, rotate: 2, opacity: 1 }} // Move left and scale up
            transition={{ type: "spring", stiffness: 100, delay: 0.1 }}
          />
        </div>

        <div className="about-photos-mobile">
          <motion.img
            src="./images/about/surf.webp"
            initial={{ x: "calc(50vw - 80px)", y: 0, scale: 0.75, rotate: 0, opacity: 0 }}
            animate={{ x: "calc(50vw - 170px)", y: 10, scale: 1, rotate: -10, opacity: 1 }}
            transition={{ type: "spring", stiffness: 100, delay: 0.2 }}
          />
          <motion.img
            src="./images/about/maine.webp"
            initial={{ x: "170px", y: 0, scale: 0.75, rotate: 0, opacity: 0 }}
            animate={{ x: "calc(50vw + 10px)", y: 10, scale: 1, rotate: 8, opacity: 1 }}
            transition={{ type: "spring", stiffness: 100, delay: 0.3, damping: 12 }}
          />
          <motion.img
            src="./images/about/office.webp"
            initial={{ x: "calc(50vw - 80px)", y: 0, scale: 0.75, rotate: 0, opacity: 0 }}
            animate={{ x: "calc(50vw - 80px)", y: -10, scale: 1, rotate: 2, opacity: 1 }} // Center
            transition={{ type: "spring", stiffness: 100, delay: 0.1 }}
          />
        </div>

        <h4>Hi, I'm Todd Hamilton</h4>

        <p>I'm a product designer and developer from Maine. I'm currently building AIProxy, a backend security tool for app developers. Previously, I was a product designer at Meta for 10 years, where I worked on various design initiatives across the company.</p>

        <p>I enjoy prototyping and building my ideas. I also love teaching other designers how to prototype. When I'm not in front of a computer, I'm spending time with family, surfing, and playing golf.</p>

        <h4>Background</h4>

        <p>I'm from the East Coast and studied film production at Ithaca College. After graduating in 2005, I spent the first few years out of college freelancing as a motion graphics designer. Between 2006 and 2010, I started gaining more professional experience in product design at a few startups in NYC.</p>

        <p>In 2010, I started working on a side project called Joypad with Lou Zell. Joypad started out as a fun weekend hack to see if we could turn an iPhone into a game controller. In a few days, we got it working with some old-school emulators and launched the first version to the App Store. After some initial coverage on Wired, TechCrunch, and Engadget, Joypad quickly became the top game controller on iOS. Over the next few years, we continued to improve Joypad and integrated our SDK in over 100 games in the App Store.</p>

        <h4>Facebook</h4>

        <p>In 2014, I had the opportunity to join the Platform Design team at Facebook. It was an exciting chance for me to bring my experience designing developer tools to a much larger audience. One of the first projects I worked on was redesigning the Like Button Plugin, which was amazing because it gets viewed over a billion times a day. During my time at Facebook, I was fortunate enough to lead design on Facebook's Social Plugins, Developer Site, Marketplace, and more.</p>

        <h4>Current</h4>

        <p>After 10 years at Facebook, I decided to leave and start AIProxy with my cofounder. After building several AI apps that required backends, we saw a need for an easier way to secure the API keys and endpoints. We've been working on AIProxy for the last year and are excited to see where it goes.</p>

    </div>

  );
}

export default About;