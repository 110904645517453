
import { Home09Icon, UserCircleIcon, TwitterIcon, GithubIcon, SmartPhone01Icon, LinkSquare01Icon, Archive02Icon } from '@hugeicons/react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'motion/react'
import Toggle from '../components/Toggle'
import './Nav.css';

function MenuItem({ to, icon: Icon, path, label, id }) {
  const location = useLocation();
  const isActive = location.pathname === path;

  const tooltipVariants = {
    initial: {
      x: 30,
      opacity: 0,
      display: 'none'
    },
    hover: {
      x: 44,
      opacity: 1,
      display: 'block'
    }
  };

  const isExternal = to.startsWith('http');

  return (
    <motion.div
      initial="initial"
      whileHover="hover"
      className={`menu-item ${isActive ? 'active' : ''}`}
      id={id}
    >
    <Link
      to={to}
      target={isExternal ? '_blank' : ''}
      rel={isExternal ? 'noreferrer' : ''}
    >
      <Icon
        color={isActive ? "var(--primary)" : "var(--primary)"}
        variant={isActive ? "solid" : "stroke"}
      />
      <motion.span
        variants={tooltipVariants}
        transition={{ type: 'spring', stiffness: 300, damping: 25 }}
      >
        {label} {isExternal ? <LinkSquare01Icon size={12} /> : ""}
      </motion.span>
    </Link>
    </motion.div>
  );
}

function Nav() {
  const tooltipVariants = {
    initial: {
      x: 30,
      opacity: 0,
      display: 'none'
    },
    hover: {
      x: 48,
      opacity: 1,
      display: 'block'
    }
  };

  return (
    <nav>
        <div className="nav-logo">
          <motion.div
            initial="initial"
            whileHover="hover"
          >
            <Link to="/">
              <img src={'./images/logo-dark.svg'} className="App-logo" alt="logo" />
              <motion.span
                variants={tooltipVariants}
                transition={{ type: 'spring', stiffness: 300, damping: 25 }}
              >
                👋 Todd Hamilton
              </motion.span>
            </Link>
          </motion.div>
        </div>

        <div className="menu">
          <MenuItem to="/" icon={Home09Icon} path="/" label="Work" />
          <MenuItem to="/about" icon={UserCircleIcon} path="/about" label="About" />
          <MenuItem to="/swiftui" icon={SmartPhone01Icon} path="/swiftui" label="SwiftUI" />
          <MenuItem to="/archive" icon={Archive02Icon} path="/archive" label="Archive" />
          {/* <MenuItem to="/writing" icon={LicenseDraftIcon} path="/writing" label="Writing" /> */}

          <MenuItem to="https://x.com/toddham" icon={TwitterIcon} label="Twitter" id="twitter" />
          <MenuItem to="https://github.com/toddham" icon={GithubIcon} label="GitHub" id="github" />

          <Toggle />
        </div>
    </nav>
  );
}

export { MenuItem };
export default Nav;