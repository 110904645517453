function PracticeRun() {
    return (

        <div className="details-body">

          <p>Practice Run was a project Lou Zell and I built while we were experimenting with OpenAI's Realtime API. We were impressed by how natural conversations felt and thought it could be really useful for doing mock interviews. Interviewing is stressful because you don't always know what questions will get thrown at you, so it's hard to simulate that environment unless you have another person to help you prep, and talking to a chatbot just isn't realistic. Practice Run gives you a much more realistic interview experience and helps you dial in your communication. And the feedback at the end gives you some pointers for what you can improve.</p>

          <p>We built the app in about a week, and it was a great way to understand all the nuances of the Realtime API. After we launched, we rolled the new security features we built for Realtime back into AIProxy.</p>

        </div>

    );
  }

  export default PracticeRun;