function ABCFinder() {
    return (

      <div className="details-body">

        <p>ABC Finder started out as an experiment to see what I could build using Core ML. Eventually, I tried out the YOLOv3 model, which does object recognition, and it worked surprisingly well.</p>

        <p>From there I got the idea to build an app to help my son learn the alphabet. The concept was to make a type of scavenger hunt game where the kid searches for common objects around the house using the camera. When they find an object, the app will read out the word and say the letter of the alphabet that it starts with.</p>

        <p>Testing the app with my son was a lot of fun and the perfect way to get feedback. I removed any objects from the game that the model was having trouble with. After a few months of hacking on it in my spare time, I eventually launched it on the App Store. Like many of the things that I build, it was a fun learning experience that gave me the chance to put Core ML to use in a unique way.</p>

      </div>

    );
  }

  export default ABCFinder;