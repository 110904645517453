import swiftData from './swiftData.json';
import './SwiftUI.css';

function SwiftUI() {
    return (

      <div className="swift">

        <div className="swift-header" style={{display: 'none'}}>
          <h1>SwiftUI Prototypes</h1>
          <p>A collection of 100+ SwiftUI prototypes, tools and experiments.</p>

          <div className="swift-cta">
            <p>Limited time offer, get the source code for all 100+ projects for just $199.</p>
          <button>Purchase for <span>$300</span> $199</button>
          </div>
        </div>

        <div className="swift-grid">
        {swiftData.map((item, index) => (
            <div className="swift-item" key={index}>
                {item.image.endsWith('.mp4') ? (
                    <video loop muted playsInline controls>
                        <source src={`${item.image}#t=0.001`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <img src={item.image} alt={item.alt} />
                )}
                <div className="swift-info">
                    <div className="info-col">
                        <div className="info-detail">{item.project}</div>
                    </div>
                </div>
            </div>
        ))}
        </div>

    </div>

    );
}

export default SwiftUI;