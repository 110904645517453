import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Modal from '../components/Modal';
import { ArrowLeft01Icon, ArrowRight01Icon, PlayIcon } from '@hugeicons/react';
import workData from './workData.json';
import './Work.css';

import { motion, AnimatePresence } from 'motion/react';

  // Import Swiper and modules
  import Swiper from 'swiper';
  import { Navigation, Pagination } from 'swiper/modules';

  // Import Swiper styles
  import 'swiper/css';

function Work({ onModalStateChange }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [postersLoaded, setPostersLoaded] = useState(false);

  // Add effect to handle body scroll locking and modal state
  useEffect(() => {
    if (showModal) {
      onModalStateChange(true)
    } else {
      onModalStateChange(false);
    }

    // Cleanup function to ensure scroll is re-enabled when component unmounts
    return () => {
      onModalStateChange(false);
    };
  }, [showModal, onModalStateChange]);

  // Add preload function
  useEffect(() => {
    const preloadPosters = async () => {
      const posterPromises = workData
        .filter(item => item.poster)
        .map(item => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
              console.log(`Successfully loaded poster: ${item.poster}`);
              resolve();
            };
            img.onerror = (error) => {
              console.error(`Failed to load poster: ${item.poster}`, error);
              reject(new Error(`Failed to load poster: ${item.poster}`));
            };
            // Ensure the path is correctly constructed
            const posterPath = `./images/${item.poster.replace(/^[–\s]+|["]/g, '')}`;
            img.src = posterPath;
          });
        });

      try {
        await Promise.all(posterPromises);
        setPostersLoaded(true);
      } catch (error) {
        console.error('Error preloading posters:', error);
        // Only set postersLoaded to true if we're in development
        if (process.env.NODE_ENV === 'development') {
          setPostersLoaded(true);
        }
      }
    };

    preloadPosters();
  }, []);

  useEffect(() => {
    if (!postersLoaded) return; // Wait for posters to load

    const swipers = document.querySelectorAll('.swiper');
    swipers.forEach(swiperEl => {
      const swiper = new Swiper(swiperEl, {
        modules: [Navigation, Pagination],
        speed: 500,
        spaceBetween: 0,
        allowTouchMove: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-controls .swiper-pagination',
          clickable: true,
        },
        init: false,
        initialSlide: 0,
        observer: true,
        observeParents: true,
        preloadImages: true,
        watchSlidesProgress: true,
      });

      swiper.on('init', () => {
        // Force update after a short delay
        setTimeout(() => {
          swiper.update();
          // Manually set poster attributes after Swiper init
          const videos = swiperEl.querySelectorAll('video');
          videos.forEach(video => {
            if (video.hasAttribute('data-poster')) {
              video.poster = video.getAttribute('data-poster');
            }
          });
        }, 100);
      });

      swiper.init();

      // Rest of your swiper event handlers...
    });

    // Rest of your useEffect code...
  }, [postersLoaded]); // Remove showModal from dependencies

  // In your video elements, store poster path in data attribute
  const getVideoPoster = (item) => {
    if (!item.poster) return undefined;
    const posterPath = `./images/${item.poster.replace(/^[–\s]+|["]/g, '')}`;
    return posterPath;
  };

  const handleVideoPlay = () => {
    setIsVideoPlaying(true);
  };

  const handleVideoPause = () => {
    setIsVideoPlaying(false);
  };

  return (

        <motion.div
          className="work"
          key="work"
        >

          {workData.map((item, index) => (
            <motion.div
              key={index}
              className="work-item"
            >
              <div className={item.images.length > 1 ? "swiper" : "single-image"}>
                {item.images.length > 1 ? (
                  <>
                    <div className="swiper-wrapper">
                      {item.images.map((image, imageIndex) => (
                        <div
                          className="swiper-slide"
                          key={imageIndex}
                        >
                          {image?.endsWith('.mp4') ? (
                            <div className="video-container">
                              <video
                                src={"./images/" + image}
                                controls={isVideoPlaying ? true : false}
                                data-poster={getVideoPoster(item)}
                                poster={getVideoPoster(item)}
                                onPlay={handleVideoPlay}
                                onPause={handleVideoPause}
                              >
                              </video>
                              {!isVideoPlaying && (
                                <button className="custom-play-button" onClick={(e) => {
                                  e.stopPropagation();
                                  if (e.currentTarget.previousElementSibling instanceof HTMLVideoElement) {
                                    e.currentTarget.previousElementSibling.play();
                                  }
                                }}>
                                  <PlayIcon size={32} color={"white"} variant={"solid"} strokeWidth={2} type={"standard"} />
                                </button>
                              )}
                            </div>
                          ) : (
                            <img
                              src={"./images/" + image}
                              alt={`${item.alt || ""} ${imageIndex + 1}`}
                              onClick={() => {
                                if (item.id === 'swiftui') {
                                  window.location.href = '#/swiftui';
                                } else {
                                  setSelectedItem(item);
                                  setShowModal(true);
                                }
                              }}
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="swiper-controls" style={{ opacity: isVideoPlaying ? 0 : 1, visibility: isVideoPlaying ? 'hidden' : 'visible' }}>
                      <div className="swiper-pagination"></div>
                    </div>
                    <div className="swiper-button-prev" style={{ opacity: isVideoPlaying ? 0 : 1, visibility: isVideoPlaying ? 'hidden' : 'visible' }}>
                      <ArrowLeft01Icon size={16} color={"white"} variant={"stroke"} strokeWidth={2} type={"standard"} />
                    </div>
                    <div className="swiper-button-next" style={{ opacity: isVideoPlaying ? 0 : 1, visibility: isVideoPlaying ? 'hidden' : 'visible' }}>
                      <ArrowRight01Icon size={16} color={"white"} variant={"stroke"} strokeWidth={2} type={"standard"} />
                    </div>
                  </>
                ) : (
                  <div
                    className="work-media"
                  >
                    {item.images[0]?.endsWith('.mp4') ? (
                      <div className="video-container">
                        <video
                          src={"./images/" + item.images[0]}
                          controls={isVideoPlaying ? true : false}
                          data-poster={getVideoPoster(item)}
                          poster={getVideoPoster(item)}
                          onPlay={handleVideoPlay}
                          onPause={handleVideoPause}
                        >
                        </video>
                        {!isVideoPlaying && (
                          <button className="custom-play-button" onClick={(e) => {
                            e.stopPropagation();
                            if (e.currentTarget.previousElementSibling instanceof HTMLVideoElement) {
                              e.currentTarget.previousElementSibling.play();
                            }
                          }}>
                            <PlayIcon size={32} color={"white"} variant={"solid"} strokeWidth={2} type={"standard"} />
                          </button>
                        )}
                      </div>
                    ) : (
                      <img
                        src={"./images/" + item.images[0]}
                        alt={item.alt || ""}
                        onClick={() => {
                          if (item.id === 'swiftui') {
                            window.location.href = '#/swiftui';
                          } else {
                            setSelectedItem(item);
                            setShowModal(true);
                          }
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </div>
                )}
              </div>

              <div className="work-info">
                <div className="info-col">
                  <div className="info-label">Project</div>
                  <div className="info-detail">{item.project}</div>
                </div>
                <div className="info-col">
                  <div className="info-label">Role</div>
                  <div className="info-detail">{item.role}</div>
                </div>
                <div className="info-col">
                  <div className="info-label">Date</div>
                  <div className="info-detail">{item.date}</div>
                </div>

                <div className="info-col actions">

                  {item.id !== 'swiftui' ? (
                    <Link className="info-action view-details"
                      onClick={() => {
                        setSelectedItem(item);
                        setShowModal(true);
                      }}
                    >
                      View Details
                    </Link>
                  ) : (
                    <Link to="/swiftui" className="info-action view-details">
                      View Prototypes
                    </Link>
                  )}

                </div>


              </div>
            </motion.div>
          ))}

          <AnimatePresence mode="wait">
            { showModal && (
              <Modal isOpen={showModal} onClose={() => setShowModal(false)} item={selectedItem} />
          )}
          </AnimatePresence>

        </motion.div>




  );
}

export default Work;