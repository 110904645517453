import React, { useEffect, useState } from 'react';
import './Toggle.css';
import { setTheme } from '../utils/themes';
import { Sun03Icon, Moon02Icon } from '@hugeicons/react';
import { motion } from 'motion/react'
import { Link } from 'react-router-dom';

function Toggle() {
    const [togClass, setTogClass] = useState('dark');
    let theme = localStorage.getItem('theme');

    const tooltipVariants = {
        initial: {
          x: 30,
          opacity: 0,
          display: 'none'
        },
        hover: {
          x: 44,
          opacity: 1,
          display: 'block'
        }
    };

    const handleOnClick = () => {
        if (localStorage.getItem('theme') === 'theme-dark') {
            setTheme('theme-light');
            setTogClass('light')
        } else {
            setTheme('theme-dark');
            setTogClass('dark')
        }
    }

    useEffect(() => {
        if (localStorage.getItem('theme') === 'theme-dark') {
            setTogClass('dark')
        } else if (localStorage.getItem('theme') === 'theme-light') {
            setTogClass('light')
        }
    }, [theme])

    return (
        <motion.div
            className="container--toggle menu-item"
            initial="initial"
            whileHover="hover"
        >
            {
                togClass === "light" ?
                <div className="menu-item" onClick={handleOnClick}>
                    <Link>
                        <Sun03Icon />
                        <motion.span
                            variants={tooltipVariants}
                            transition={{ type: 'spring', stiffness: 300, damping: 25 }}
                        >
                            Dark mode
                        </motion.span>
                    </Link>
                </div>
                :
                <div className="menu-item" onClick={handleOnClick}>
                    <Link>
                        <Moon02Icon />
                        <motion.span
                            variants={tooltipVariants}
                            transition={{ type: 'spring', stiffness: 300, damping: 25 }}
                        >
                            Light mode
                        </motion.span>
                    </Link>
                </div>
            }
        </motion.div>
    )
}

export default Toggle;