import React, { useEffect, useState } from 'react';
import { Routes, Route, HashRouter, useLocation } from 'react-router-dom';
import Work from './pages/Work';
import About from './pages/About';
import SwiftUI from './pages/SwiftUI';
import Archive from './pages/Archive';

import Nav from './components/Nav';
import { keepTheme } from './utils/themes';

import './App.css';
import { AnimatePresence } from 'motion/react';

// ensure page is scrolled to top when switching
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    keepTheme();
  })

  const handleModalStateChange = (isOpen) => {
    setIsModalOpen(isOpen);
  };

  return (
    <HashRouter>
      <ScrollToTop />
      <div className="App">
        <main className={isModalOpen ? 'modal-open' : ''}>
          <Nav />

          <AnimatePresence mode="wait">
            <Routes>
              <Route path="/" element={<Work onModalStateChange={handleModalStateChange} />} />
              <Route path=":projectId" element={<Work onModalStateChange={handleModalStateChange} />} />
              <Route path="/about" element={<About />} />
              <Route path="/swiftui" element={<SwiftUI />} />
              <Route path="/archive" element={<Archive />} />
            </Routes>
          </AnimatePresence>
        </main>
      </div>
    </HashRouter>
  );
}

export default App;
