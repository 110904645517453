function FigmaToSwift() {
    return (

      <div className="details-body">

         <p>Figma to Swift is a Figma plugin that generates SwiftUI from your design tokens and exports them as a Swift package. Once included in Xcode, developers will have access to design tokens directly through Xcode's editor and code complete.</p>

      </div>

    );
  }

  export default FigmaToSwift;