import { motion } from 'motion/react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import AIProxy from '../pages/subpages/AIProxy';
import AppleWatch from '../pages/subpages/AppleWatch';
import FB4D from '../pages/subpages/FB4D';
import FigmaToSwift from '../pages/subpages/FigmaToSwift';
import Joypad from '../pages/subpages/Joypad';
import LikeButton from '../pages/subpages/LikeButton';
import Marketplace from '../pages/subpages/Marketplace';
import Letterfall from '../pages/subpages/Letterfall';
import ABCFinder from '../pages/subpages/ABCFinder';
import PracticeRun from '../pages/subpages/PracticeRun';
import MetaPrototyping from '../pages/subpages/MetaPrototyping';
import { MultiplicationSignIcon } from '@hugeicons/react';
import './Modal.css';

function Modal({ onClose, item }) {
    useEffect(() => {
        // Get the width of the scrollbar
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

        // Add padding to the body to prevent layout shift
        document.body.style.paddingRight = `${scrollbarWidth}px`;
        document.body.style.overflow = 'hidden';

        // Re-enable scrolling and remove padding when modal is closed
        return () => {
            document.body.style.overflow = 'unset';
            document.body.style.paddingRight = '0px';
        };
    }, []);

    const handleBackgroundClick = (e) => {
        // Only close if clicking the backdrop (modal), not the content
        if (e.target.className === 'modal') {
          onClose();
        }
      };

  return (
    <motion.div
        className="modal"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{
            opacity: 0
         }}
         onClick={handleBackgroundClick}  // Add click handler here
    >
        <motion.div
            className="modal-sidebar"
            initial={{ y: "150%" }}
            animate={{
                y: "0%",
                transition: {
                    type: 'spring', stiffness: 315, damping: 27, mass: 0.8
                }
            }}
            exit={{ y: "150%" }}
        >
            <button onClick={onClose} className="modal-close">
                <MultiplicationSignIcon
                    size={20}
                    color="var(--tertiary)"
                />
            </button>

            <div className="modal-content">
            <h2>{item.project}</h2>
            <div className="modal-info">
                <div className="modal-row">
                    <div className="modal-label">Role:</div>
                    <div className="modal-detail">{item.role}</div>
                </div>
                <div className="modal-row">
                    <div className="modal-label">Date:</div>
                    <div className="modal-detail">{item.date}</div>
                </div>
                {(item.link !== "" ) && (
                    <div className="modal-row">
                        <div className="modal-label">Site:</div>
                        <div className="modal-detail"><a href={`http://${item.link}`} target="_blank" rel="noopener noreferrer">{item.link}</a></div>
                    </div>
                )}

            </div>
            {item.id === "aiproxy" && <AIProxy />}
            {item.id === "apple-watch" && <AppleWatch />}
            {item.id === "fb4d" && <FB4D />}
            {item.id === "figmatoswift" && <FigmaToSwift />}
            {item.id === "joypad" && <Joypad />}
            {item.id === "like-button" && <LikeButton />}
            {item.id === "marketplace" && <Marketplace />}
            {item.id === "letterfall" && <Letterfall />}
            {item.id === "abcfinder" && <ABCFinder />}
            {item.id === "practicerun" && <PracticeRun />}
            {item.id === "meta-prototyping" && <MetaPrototyping />}
            </div>
        </motion.div>
    </motion.div>
  );
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    item: PropTypes.object,
  };

export default Modal;