function LikeButton() {
    return (

      <div className="details-body">

        <p>The Like Button is one of the most iconic products in the world with over 10 billion views each day across millions of websites. In 2014 I had the opportunity to redesign the Like Button along with the entire suite of social plugins for developers. This included the Comments Plugin, Embedded Posts, Photos, and Videos.</p>

        {/* <ul>
          <li>The social plugin code was outdated and causing performance issues on websites.</li>
          <li>Plugins weren't responsive which was causing problems on mobile which was important for growth.</li>
          <li>The branding and design language had fallen behind with the Facebook website and mobile app.</li>
        </ul> */}

        <p>I worked closely with the engineering team and I wrote a lot of the intial front-end code to make sure each plugin could adapt to different scenerios. I also wrote custom animation code for the new Like Button which had to be performant and reliable.</p>

        <p>We succesfully launched the updated plugins at F8, Facebooks annual developer conference. The results of the redesign were very positive and led to better performance, engagement, and a more consistent experience for users.</p>

      </div>

    );
}

export default LikeButton;